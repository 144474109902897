import { Button, Modal, Table, TableColumnsType } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import HeaderSevice from "../../../components/HeaderService";
import image_partner_avt from "../../../assets/images/image_partner_avt.png";
import icon_phone from "../../../assets/icons/icon_phone.png";

import { formatPrice } from "../../../utils/func";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { takeJobPartner } from "../../../redux/Slices/partnerSlice";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import paths from "../../../config";

export const TaskInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedJob, setSelectedJob] = useState<Array<number>>([]);
  //   const jobData = useLocation().state.job;
  //   const userData = useLocation().state.user;
  const { state } = useLocation();
  const loadingTakeJob = useAppSelector((state) => state.partner.loadingTakeJob);
  //   const responseTakeJob = useAppSelector((state) => state.partner.responseTakeJob);
  const jobData = state?.item?.job;
  const scheduleData = state?.item?.schedule;
  const { t, i18n } = useTranslation(["calendar"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const handleTakeJob = async () => {
    try {
      const response: any = await dispatch(takeJobPartner(selectedJob));
      if (response?.payload?.status === 1) {
        Modal.success({
          title: t("ban_da_nhan_cong_viec_thanh_cong"),
          content: (
            <div className="success-modal">
              {/* <h1 className="success-modal-title">Bạn đã nhận lịch thành công</h1> */}
              {/* <p className="success-modal-text">Thông tin người đặt lịch</p> */}
              <div className="success-modal-avt">
                <img
                  src={(scheduleData[0]?.user?.avatar !== "" && scheduleData[0]?.user?.avatar) || image_partner_avt}
                  alt=""
                />
              </div>
              <span className="success-modal-name">{scheduleData[0]?.user?.name}</span>
              <a href={`tel:${scheduleData[0]?.user?.telephone}`} className="success-modal-phone">
                <div className="success-modal-phone-icon">
                  <img src={icon_phone} alt="" />
                </div>
                <span className="success-modal-phone-number">{scheduleData[0]?.user?.telephone}</span>
              </a>
            </div>
          ),
          onOk: () =>
            // navigate("/partner/home")
            navigate(paths.homePartner),
          className: "take-job-success",
        });
      } else if (response?.payload?.status === 0) {
        Modal.error({
          title: t("nhan_cong_viec_khong_thanh_cong"),
          //   content: response?.payload?.message.map((item: any) => item),
          content: (
            <ul className="list-error">
              {response?.payload?.message.map((item: any, index: number) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          ),
        });
      }
    } catch (error) {
      Modal.error({
        title: t("nhan_cong_viec_khong_thanh_cong"),
        content: t("vui_long_thu_lai_sau"),
      });
    }
  };
  //   console.log(jobData);
  const columns: TableColumnsType<any> = [
    {
      title: t("ngay_lam_viec"),
      dataIndex: "day_working",
      width: 120,
    },
    {
      title: t("gio_bat_dau"),
      dataIndex: "time_start",
      width: 120,
    },
    {
      title: t("gio_ket_thuc"),
      dataIndex: "time_end",
      width: 120,
    },
    // {
    //   title: t("gia_dich_vu"),
    //   dataIndex: "price",
    //   width: 120,
    // },
    // {
    //   title: t("khuyen_mai"),
    //   dataIndex: "discount",
    //   width: 120,
    // },
    {
      title: t("gia_tri_ca_lam"),
      dataIndex: "price_discount",
      width: 120,
    },
    {
      title: t("phi_quan_ly_app"),
      dataIndex: "app_fee",
      width: 120,
    },
    {
      title: t("tong_thu_cua_partner"),
      dataIndex: "price_total",
      width: 120,
    },
  ];
  //   console.log(selectedJob);
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      //   const selectedRowKeysConvert = selectedRowKeys.map((item) => Number(item));
      //   setSelectedJob(selectedRowKeysConvert);
      //   const ids = selectedJob.map((item) => scheduleData[item].id);
      //   console.log(ids);
      setSelectedJob(selectedRowKeys?.map((item: any) => scheduleData[item].id));
    },
    // getCheckboxProps: (record: any) => ({
    //   disabled: record.name === "Disabled User", // Column configuration not to be checked
    //   name: record.name,
    // }),
  };
  const data = scheduleData
    ?.sort((a: any, b: any) => {
      return dayjs(a.day_working).unix() - dayjs(b.day_working).unix();
    })
    ?.map((item: any, index: number) => ({
      key: index,
      day_working: dayjs(item.day_working).format("DD-MM-YYYY"),
      time_start: item.time_start,
      time_end: item.time_end,
      //   price: formatPrice(Number(item.price)),
      //   discount: formatPrice(Number(item.discount)),
      price_discount: formatPrice(Number(item.price) - Number(item.discount)),
      app_fee: formatPrice(Number(item.app_fee) - Number(item.discount)),
      price_total: formatPrice(Number(item.price) - Number(item.app_fee)),
    }));
  return (
    <div className="wrapper task-info-partner">
      <HeaderSevice text={t("chi_tiet")} />
      <div className="task-info">
        <div className="booking-info">
          <p className="booking-info-title">{t("thong_tin_dat_lich")}</p>
          <table className="booking-info-detail">
            <tbody>
              <tr>
                <td className="booking-info-key">{t("ma_dich_vu")}: </td>
                <td className="booking-info-value">{jobData?.service?.service_code}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("dich_vu")}: </td>
                <td className="booking-info-value">{jobData?.service?.name}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("nhom_dich_vu")}: </td>
                <td className="booking-info-value">{jobData?.service?.category}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("ngay_bat_dau")}: </td>
                <td className="booking-info-value">{dayjs(jobData?.detail?.day_start).format("DD-MM-YYYY")}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("gio_bat_dau")}: </td>
                <td className="booking-info-value">{jobData?.detail?.time_start}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="user-info">
          <div className="user-info-name">
            <span className="title">{t("nguoi_su_dung_dich_vu")}</span>
            <p className="content">{jobData?.user}</p>
          </div>
          <div className="user-info-address">
            <span className="title">{t("dia_chi_su_dung_dich_vu")}</span>
            <p className="content">{jobData?.address}</p>
          </div>
          <div className="user-info-note">
            <span className="title">{t("nguoi_dung_nhac_nho")}</span>
            <p className="content">{jobData?.note ? jobData?.note : "--"}</p>
          </div>
        </div>
        <div className="payment-info">
          <table className="payment-info-detail">
            <tbody>
              <tr>
                <td className="payment-info-key">{t("gia_dich_vu")}</td>
                <td className="payment-info-value">
                  {formatPrice(Number(jobData?.price) + Number(jobData?.discount))}
                </td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("khuyen_mai")}</td>
                <td className="payment-info-value">{formatPrice(Number(jobData?.discount))}</td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("gia_sau_khuyen_mai")}</td>
                {/* <td className="payment-info-value price">{formatPrice(+jobData?.discount)}</td> */}
                <td className="payment-info-value">{formatPrice(Number(jobData?.price))}</td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("phi_quan_ly_app")}</td>
                {/* <td className="payment-info-value price">{formatPrice(+jobData?.discount)}</td> */}
                <td className="payment-info-value">
                  {formatPrice(Number(jobData?.app_fee) - Number(jobData?.discount))}
                </td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("tong_thu_cua_partner")}</td>
                {/* <td className="payment-info-value price">{formatPrice(+jobData?.app_fee)}</td> */}
                <td className="payment-info-value price">
                  {formatPrice(Number(jobData?.price) - (Number(jobData?.app_fee) - Number(jobData?.discount)))}
                </td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("phuong_thuc_thanh_toan")}</td>
                <td className="payment-info-value">{jobData?.payment_method_title}</td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("tinh_trang")}</td>
                <td className="payment-info-value">{jobData?.paid_title}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="list-task">
          <p className="list-task-title">
            <span className="text">{t("cac_ca_lam_viec")}</span>
            <span className="count">{`${t("con_lai")}: ${jobData?.pending_schedules}/${jobData?.total_schedules} ${t(
              "ca",
            )}`}</span>
          </p>
          <div className="list-task-table">
            <Table
              rowSelection={{ type: "checkbox", ...rowSelection }}
              size="middle"
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{ y: 380 }}
            />
          </div>
        </div>
        <div className="task-confirm-button">
          <Button
            className="confirm-button primary"
            loading={loadingTakeJob}
            onClick={handleTakeJob}
            disabled={selectedJob?.length <= 0}
          >
            {t("nhan_viec")}
          </Button>
        </div>
        {/* <div className="success-modal">
          <button className="success-modal-close">✕</button>
          <h1 className="success-modal-title">Bạn đã nhận lịch thành công</h1>
          <p className="success-modal-text">Thông tin người đặt lịch</p>
          <div className="success-modal-avt">
            <img src="../../assets/images/image_partner_avt.png" alt="" />
          </div>
          <span className="success-modal-name">Trần Hùng</span>
          <a href="tel:0977977977" className="success-modal-phone">
            <div className="success-modal-phone-icon">
              <img src="../../assets/icons/icon_phone.png" alt="" />
            </div>
            <span className="success-modal-phone-number">0977977977</span>
          </a>
        </div> */}
      </div>
      <div className="overlay"></div>
    </div>
  );
};
