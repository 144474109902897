import { Button, Form, Input, Result, Table, TableColumnsType } from "antd";
import HeaderSevice from "../../../components/HeaderService";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { searchJob } from "../../../redux/Slices/serviceSlice";
import overLoader from "../../../components/overLoader";

export const SearchJob = () => {
  const dispatch = useAppDispatch();
  const serviceState = useAppSelector((state) => state.service);

  const handleSearchJob = async (values: any) => {
    dispatch(searchJob(values?.code));
  };

  const columns: TableColumnsType = [
    {
      key: "day_start",
      title: "Ngày bắt đầu",
      dataIndex: "day_working",
      className: "search-job-result-task-table-cell",
    },
    {
      key: "time_start",
      title: "Giờ bắt đầu",
      dataIndex: "time_start",
      className: "search-job-result-task-table-cell",
    },
    {
      key: "tasker",
      title: "Người thực hiện",
      dataIndex: "tasker_name",
      className: "search-job-result-task-table-cell",
    },
    { key: "phone", title: "Số điện thoại", dataIndex: "tasker_phone", className: "search-job-result-task-table-cell" },
  ];
  const data = serviceState?.searchJob?.schedules.map((item, index) => ({
    key: index,
    day_working: item?.day_working,
    time_start: item?.time_start,
    tasker_name: item?.tasker_name ? <span className="bold">{item?.tasker_name}</span> : "",
    tasker_phone: item?.tasker_phone ? item?.tasker_phone : "",
  }));

  return (
    <div className="wrapper search-job">
      {serviceState?.loading && overLoader()}
      <HeaderSevice text="Chi tiết công việc" />
      <div className="content-container">
        <Form className="search-job-form" onFinish={handleSearchJob}>
          <Form.Item
            name="code"
            rules={[{ required: true, message: "Vui lòng nhập mã công việc" }]}
            className="search-job-form-item"
          >
            <Input placeholder="Mã công việc" className="search-job-form-item-input" />
          </Form.Item>
          <Form.Item className="search-job-form-item">
            <Button type="primary" htmlType="submit" className="search-job-form-button">
              Tìm kiếm
            </Button>
          </Form.Item>
        </Form>
        {serviceState?.searchJob && (
          <div className="search-job-result">
            <div className="search-job-result-info">
              <div className="search-job-result-info-item">
                <p className="search-job-result-info-item-title">Tên công việc</p>
                <p className="search-job-result-info-item-text">{serviceState?.searchJob?.service_name}</p>
              </div>
              <div className="search-job-result-info-item">
                <p className="search-job-result-info-item-title">Người dùng dịch vụ</p>
                <p className="search-job-result-info-item-text">{serviceState?.searchJob?.user}</p>
              </div>
            </div>
            <div className="search-job-result-task">
              <p className="search-job-result-task-title">Danh sách lịch hẹn</p>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                className="search-job-result-task-table"
                rowClassName="search-job-result-task-table-row"
              />
            </div>
          </div>
        )}
        {typeof serviceState?.error !== "string" && serviceState?.error?.status === 404 && (
          <Result status="404" title="404" subTitle={serviceState.error.message} />
        )}
      </div>
    </div>
  );
};
