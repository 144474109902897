import HeaderSevice from "../../../components/HeaderService";
import { useLocation } from "react-router-dom";
import { formatPrice } from "../../../utils/func";
import { Image, Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { userApi } from "../../../api/userApi";
import overLoader from "../../../components/overLoader";
import image_partner_avt from "../../../assets/images/image_partner_avt.png";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

export const CalendarDetail = () => {
  const [api, showPopup] = notification.useNotification();
  const jobDetail = useLocation().state;
  const [open, setOpen] = useState<boolean>(false);
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [infoPartner, setInfoPartner] = useState<any>({});
  const { t, i18n } = useTranslation(["calendar"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const getInfoPartner = async (id: number) => {
    setStatusLoader(true);
    try {
      const response: any = await userApi.getTaskerDocument(id);
      //   console.log(response);
      if (response.status === 1) {
        setOpen(true);
        setStatusLoader(false);
        setInfoPartner(response);
      } else if (response.status === 0) {
        setStatusLoader(false);
        alertFail(response?.message);
      } else {
        setStatusLoader(false);
        alertFail(response?.message);
      }
      // if (response.status === 200) {
      //     console.log(response.data);
      // }
    } catch (error: any) {
      setStatusLoader(false);
      alertFail(error?.message);
    }
  };

  return (
    <div className="wrapper calendar-detail">
      {statusLoader && overLoader()}
      {showPopup}
      <HeaderSevice text={t("chi_tiet")} />
      <div className="calendar-detail">
        <div className="booking-info">
          <p className="booking-info-title">{t("thong_tin_dat_lich")}</p>
          <table className="booking-info-detail">
            <tbody>
              <tr>
                <td className="booking-info-key">{t("ma_dich_vu")}</td>
                <td className="booking-info-value">{jobDetail?.id}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("ten_dich_vu")}</td>
                <td className="booking-info-value">{jobDetail?.service}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("ngay_bat_dau")}</td>
                <td className="booking-info-value">{jobDetail?.day_working}</td>
              </tr>
              <tr>
                <td className="booking-info-key">{t("gio_bat_dau")}</td>
                <td className="booking-info-value">{jobDetail?.time_start}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="partner-info">
          <div className="partner-info-name">
            <span className="title">{t("nguoi_thuc_hien")}</span>
            <span className="content">{jobDetail?.tasker?.name}</span>
          </div>
          <div className="partner-info-phone">
            <span className="title">{t("so_dien_thoai")}</span>
            <span className="content">{jobDetail?.tasker?.telephone}</span>
          </div>
          {jobDetail?.tasker?.id !== 0 && (
            <button
              onClick={() => {
                //   setOpen(true);
                getInfoPartner(jobDetail?.tasker?.id);
              }}
              className="partner-info-detail primary"
            >
              {t("xem_chi_tiet")}
            </button>
          )}
        </div>
        <div className="payment-info">
          <table className="payment-info-detail">
            <tbody>
              <tr>
                <td className="payment-info-key">{t("gia_dich_vu")}</td>
                <td className="payment-info-value">
                  {/* {jobDetail?.price} */}
                  {formatPrice(Number(jobDetail?.price))}
                </td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("giam_gia")}</td>
                <td className="payment-info-value">{formatPrice(Number(jobDetail?.discount))}</td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("thanh_toan")}</td>
                <td className="payment-info-value price">
                  {formatPrice(Number(jobDetail?.price) - Number(jobDetail?.discount))}
                </td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("phuong_thuc_thanh_toan")}</td>
                <td className="payment-info-value">{jobDetail?.payment_method_title}</td>
              </tr>
              <tr>
                <td className="payment-info-key">{t("tinh_trang")}</td>
                <td className="payment-info-value">{jobDetail?.paid_title}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {jobDetail?.tasker?.id !== 0 && (
        <Modal
          title={t("thong_tin_nguoi_thuc_hien")}
          centered
          open={open}
          onCancel={() => setOpen(!open)}
          footer={null}
          className="partner-info-modal"
        >
          <div className="partner-info-modal-item-image">
            <img src={jobDetail?.tasker?.avatar !== "" ? jobDetail?.tasker?.avatar : image_partner_avt} alt="" />
          </div>
          <div className="partner-info-modal-item">
            <span className="partner-info-modal-item-title">{t("ho_ten")}</span>
            <span className="partner-info-modal-item-content">{jobDetail?.tasker?.name}</span>
          </div>
          <div className="partner-info-modal-item">
            <span className="partner-info-modal-item-title">{t("so_dien_thoai")}</span>
            <span className="partner-info-modal-item-content">{jobDetail?.tasker?.telephone}</span>
          </div>
          <div className="partner-info-modal-item">
            <span className="partner-info-modal-item-title">{t("chuyen_mon")}</span>
            <span className="partner-info-modal-item-content">{jobDetail?.tasker?.job}</span>
          </div>
          <div className="partner-info-modal-item">
            <span className="partner-info-modal-item-title">{t("danh_gia_trung_binh")}</span>
            <div className="partner-info-modal-item-content rate">
              <span className="text">{jobDetail?.tasker?.avg_rate}</span>
              <span className="star">★</span>
            </div>
          </div>
          {infoPartner?.documents?.length > 0 && (
            <div className="partner-info-modal-item document">
              <span className="partner-info-modal-item-title">{t("hinh_anh_ho_so")}</span>
              {/* <span className="partner-info-modal-item-content">{jobDetail?.tasker?.avg_rate} ⭐</span> */}
              <div className="document-list">
                <Image.PreviewGroup preview={{ rootClassName: "document-image-preview" }}>
                  {infoPartner?.documents?.map((item: any, index: number) => {
                    return (
                      <div key={index} className="document-item">
                        {/* <Image src={item} alt="" className="document-item-image" /> */}
                        <Image src={item} alt="" className="document-item-image" />
                      </div>
                    );
                  })}
                </Image.PreviewGroup>
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};
