import config from "../config";
import { RouteType } from "../types/routes";

import {
  Login,
  Register,
  ResetPassword,
  HomePartner,
  HomeUser,
  SettingUser,
  LoginPartner,
  RegisterPartner,
  SettingPartner,
  ChangePassWordPartner,
  ChangeLanguagePartner,
  ServiceDetail,
  ListServiceByCategoryId,
  MyAccountPartner,
  CalenderPartner,
  FamilyInfo,
  FormAddRelative,
  TheJobs,
  JobDetail,
  Notification,
  FormUpdateRelative,
  NotificationDetail,
  Statistic,
  CalendarDetail,
  PromotionDetail,
  Wallet,
  StatisticPartner,
  NotificationPartner,
  NotifyDetailPartner,
  Report,
  BookingType,
  SelectUser,
  Booking,
  ServiceConfirmation,
  CalendarPartnerDetail,
  TransferInfo,
  MyAccount,
  ChangePassword,
  ChangeLanguage,
  Terms,
  PrivacyPolicy,
  Calendar,
  Promotion,
  CreatePassByAuth,
  FreeCourse,
  HealthKnowledge,
  BookingMap,
  Selectpayment,
  About,
  Help,
  Task,
  TaskInfo,
  RegisterService,
  RegisterServiceDetail,
  BankInfo,
  Rule,
  SelectBank,
  RegisterSubServiceDetail,
  SearchJob,
} from "../page";

import DefaultLayout from "../layouts/DefaultLayout";

/* PARTNER ROUTES */
export const partnerRoutes: any = [
  {
    path: config.homePartner,
    component: HomePartner,
    layout: DefaultLayout,
  },
  // setting
  {
    path: config.settingPartner,
    component: SettingPartner,
    layout: DefaultLayout,
  },
  {
    path: config.myAccountPartner,
    component: MyAccountPartner,
    layout: DefaultLayout,
  },
  {
    path: config.changePasswordPartner,
    component: ChangePassWordPartner,
    layout: DefaultLayout,
  },
  {
    path: config.changeLanguagePartner,
    component: ChangeLanguagePartner,
    layout: DefaultLayout,
  },
  {
    path: config.termsPartner,
    component: Terms,
    layout: DefaultLayout,
  },
  {
    path: config.privacyPolicyPartner,
    component: PrivacyPolicy,
    layout: DefaultLayout,
  },
  // banner
  {
    path: config.calendarPartner,
    component: CalenderPartner,
    layout: DefaultLayout,
  },
  {
    path: config.calendarPartnerDetail,
    component: CalendarPartnerDetail,
  },
  {
    path: config.walletPartner,
    component: Wallet,
    layout: DefaultLayout,
  },
  {
    path: config.bankInfoPartner,
    component: BankInfo,
    layout: DefaultLayout,
  },
  {
    path: config.ruleWalletPartner,
    component: Rule,
    layout: DefaultLayout,
  },
  {
    path: config.selectBank,
    component: SelectBank,
    layout: DefaultLayout,
  },
  {
    path: config.taskPartner,
    component: Task,
    layout: DefaultLayout,
  },
  {
    path: config.taskDetailPartner,
    component: TaskInfo,
    layout: DefaultLayout,
  },
  {
    path: config.statisticPartner,
    component: StatisticPartner,
    layout: DefaultLayout,
  },
  {
    path: config.registerServicePartner,
    component: RegisterService,
    layout: DefaultLayout,
  },
  {
    path: config.registerServiceDetailPartner,
    component: RegisterServiceDetail,
    layout: DefaultLayout,
  },
  {
    path: config.registerSubServiceDetailPartner,
    component: RegisterSubServiceDetail,
    layout: DefaultLayout,
  },
  {
    path: config.notifyPartner,
    component: NotificationPartner,
    layout: DefaultLayout,
  },
  {
    path: config.notifyDetailPartner,
    component: NotifyDetailPartner,
    layout: DefaultLayout,
  },
  {
    path: config.reportPartner,
    component: Report,
    layout: DefaultLayout,
  },
  {
    path: config.helpPartner,
    component: Help,
    layout: DefaultLayout,
  },
  {
    path: config.aboutPartner,
    component: About,
    layout: DefaultLayout,
  },
];

/* USER ROUTES */
export const userRoutes: RouteType[] = [
  {
    path: config.homeUser,
    component: HomeUser,
    layout: DefaultLayout,
  },
  // --- setting
  {
    path: config.settingUser,
    component: SettingUser,
    layout: DefaultLayout,
  },
  {
    path: config.myAccountUser,
    component: MyAccount,
    layout: DefaultLayout,
  },
  {
    path: config.changePasswordUser,
    component: ChangePassword,
    layout: DefaultLayout,
  },
  {
    path: config.changeLanguageUser,
    component: ChangeLanguage,
    layout: DefaultLayout,
  },
  {
    path: config.termsUser,
    component: Terms,
    layout: DefaultLayout,
  },
  {
    path: config.privacyPolicyUser,
    component: PrivacyPolicy,
    layout: DefaultLayout,
  },
  // --- siderbar
  {
    path: config.calendarUser,
    component: Calendar,
    layout: DefaultLayout,
  },
  {
    path: config.calendarUserDetail,
    component: CalendarDetail,
    layout: DefaultLayout,
  },
  {
    path: config.promotionUser,
    component: Promotion,
    layout: DefaultLayout,
  },
  {
    path: config.promotionDetailUser,
    component: PromotionDetail,
    layout: DefaultLayout,
  },
  {
    path: config.familyInfoUser,
    component: FamilyInfo,
    layout: DefaultLayout,
  },
  {
    path: config.addFamilyInfoUser,
    component: FormAddRelative,
    layout: DefaultLayout,
  },
  {
    path: config.updateFamilyInfoUser,
    component: FormUpdateRelative,
    layout: DefaultLayout,
  },
  {
    path: config.theJobsUser,
    component: TheJobs,
    layout: DefaultLayout,
  },
  {
    path: config.jobDetailUser,
    component: JobDetail,
    layout: DefaultLayout,
  },
  {
    path: config.freeCourseUser,
    component: FreeCourse,
    layout: DefaultLayout,
  },
  {
    path: config.healthKnowledgeUser,
    component: HealthKnowledge,
    layout: DefaultLayout,
  },
  {
    path: config.notificationUser,
    component: Notification,
    layout: DefaultLayout,
  },
  {
    path: config.notificationUserDetail,
    component: NotificationDetail,
    layout: DefaultLayout,
  },
  {
    path: config.statisticUser,
    component: Statistic,
    layout: DefaultLayout,
  },
  {
    path: config.aboutUser,
    component: About,
    layout: DefaultLayout,
  },
  {
    path: config.helpUser,
    component: Help,
    layout: DefaultLayout,
  },
  // booking service
  {
    path: config.booking,
    component: Booking,
    layout: DefaultLayout,
  },
  {
    path: config.bookingMap,
    component: BookingMap,
    layout: DefaultLayout,
  },

  {
    path: config.bookingType,
    component: BookingType,
    layout: DefaultLayout,
  },

  {
    path: config.selectPayment,
    component: Selectpayment,
    layout: DefaultLayout,
  },
  {
    path: config.selectUser,
    component: SelectUser,
    layout: DefaultLayout,
  },
  {
    path: config.serviceConfirm,
    component: ServiceConfirmation,
    layout: DefaultLayout,
  },
  {
    path: config.transferInfo,
    component: TransferInfo,
    layout: DefaultLayout,
  },
  // categories
  {
    path: config.listServiceByCategory,
    component: ListServiceByCategoryId,
    layout: DefaultLayout,
  },
  {
    path: config.serviceDetail,
    component: ServiceDetail,
    layout: DefaultLayout,
  },
];

/* PUBLIC ROUTES */
const subDomain = window.location.host.split(".")[0];
const pulicPartnerRoutes: RouteType[] = [
  { path: config.loginPartner, component: LoginPartner },
  { path: config.registerPartner, component: RegisterPartner },
  { path: config.resetPassword, component: ResetPassword },
  { path: config.createPasswordPartner, component: CreatePassByAuth },
  { path: config.terms, component: Terms },
];
const publicUserRoutes: RouteType[] = [
  { path: config.login, component: Login },
  { path: config.register, component: Register },
  { path: config.resetPassword, component: ResetPassword },
  { path: config.terms, component: Terms },
  { path: config.createPassword, component: CreatePassByAuth },
  { path: config.searchJob, component: SearchJob },
];
export const publicRoutes: RouteType[] =
  subDomain === "partner" ? pulicPartnerRoutes : subDomain === "app" ? publicUserRoutes : publicUserRoutes;
